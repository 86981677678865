import { render, staticRenderFns } from "./repairs.vue?vue&type=template&id=37165c18&scoped=true&"
import script from "./repairs.vue?vue&type=script&lang=js&"
export * from "./repairs.vue?vue&type=script&lang=js&"
import style0 from "./repairs.vue?vue&type=style&index=0&id=37165c18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37165c18",
  null
  
)

export default component.exports