<template>
	<!-- 报事/报修 -->
	<div class="page_main">
		<!-- <van-nav-bar title="投诉/建议"
                 left-text="返回"
                 left-arrow
                 @click-left="onClickLeft" /> -->
		<van-form @submit="onSubmit">
			<div class="title_area fs_14">个人信息</div>
			<van-field v-model="formObj.contact" name="contact" label="联系人" placeholder="请输入联系人" />

			<van-field v-model="formObj.contactPhone" name="contactPhone" label="联系电话" placeholder="请输入联系电话" />
			<div class="title_area fs_14">报修信息</div>

			<van-field
				readonly
				clickable
				name="repaire"
				right-icon="arrow-down"
				:value="formObj.buildingName"
				label="楼栋名称"
				placeholder="点击选择楼栋名称"
				@click="buildingIdShow = true"
			/>
			<van-popup v-model="buildingIdShow" position="bottom"><van-picker show-toolbar :columns="buildingIdArr" @confirm="onConfirmbuildingId" @cancel="buildingIdShow = false" /></van-popup>

			<van-field v-model="formObj.repairLocation" name="repairLocation" label="报修地点" placeholder="请输入报修地点" />
			<van-field
				readonly
				clickable
				name="repaire"
				right-icon="arrow-down"
				:value="formObj.repaire"
				label="报修类型"
				placeholder="点击选择报修类型"
				@click="showPicker = true"
			/>
			<van-popup v-model="showPicker" position="bottom"><van-picker show-toolbar :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" /></van-popup>

			<van-field
				readonly
				clickable
				name="repair"
				:value="formObj.repair"
				label="报修位置"
				right-icon="arrow-down"
				placeholder="点击选择报修位置"
				@click="showPickerP = true"
			/>
			<van-popup v-model="showPickerP" position="bottom"><van-picker show-toolbar :columns="repairPartAry" @confirm="onConfirmP" @cancel="showPickerP = false" /></van-popup>

			<!-- <div class=" mt_8"></div> -->

			<van-field
				v-model="formObj.description"
				name="description"
				label="问题描述"
				rows="2"
				autosize
				type="textarea"
				maxlength="50"
				show-word-limit
				placeholder="请输入问题描述"
			/>

			<!-- <div class=" mt_8"></div> -->

			<van-field name="uploader" label="上传照片">
				<template #input>
					<van-uploader :max-count="3" :after-read="afterRead" :before-delete="beforeDelete" v-model="formObj.photosAry" />
				</template>
			</van-field>

			<div style="margin: 16px;"><van-button block :disabled="isDisabled" :loading="isDisabled" type="info" color="#21AA61" native-type="submit">提交</van-button></div>
		</van-form>
	</div>
</template>
<script>
import { initDictOptions } from '@/api/login.js';
import { report } from '@/api/upload.js';

import { commitRepairs,getBuildingByTenantId } from '@/api/repairs.js';
import { Toast } from 'vant';
export default {
	props: {},
	components: {},
	data() {
		return {
			// form表单所有数据
			formObj: {
				repaire: '', // 报修类型
				repaireType: '', // 报修类型
				repairLocation: '', // 报修地点
				repair: '', // 报修位置
				repairPart: '', // 报修位置
				contact: '', // 联系人
				contactPhone: '', // 联系电话
				description: '', // 问题描述
				photosAry: [], // 上传照片
				photoss: [] ,// 上传照片
				buildingId:'',
				buildingName:'',
			},
			columns: [],
			repairPartAry: [],
			showPicker: false,
			showPickerP: false,
			buildingIdShow:false,
			buildingIdArr:[],
			isDisabled: false,
		};
	},
	created() {
		var user = localStorage.getItem('userInfo');
		if (user) {
			user = JSON.parse(user);
			this.formObj.contact = user.userName;
			this.formObj.contactPhone = user.phone;
		}
		this.getType(); // 报修类型
		this.getLocation();
		this.getBuildingByTenantId();
	},
	mounted() {},
	methods: {
		getBuildingByTenantId(){
			getBuildingByTenantId().then(res => {
				const ary = [];
				res.data.forEach(item => {
					ary.push({
						text: item.buildingName,
						value: item.uuid
					});
				});
				this.buildingIdArr = ary;
			});
		},
		// 返回上一页
		onClickLeft() {
			history.back();
		},
		// 报修类型选择确定
		onConfirm(v) {
			this.formObj.repaire = v.text;
			this.formObj.repaireType = v.value;
			this.showPicker = false;
		},
		onConfirmP(v) {
			this.formObj.repair = v.text;
			this.formObj.repairPart = v.value;
			this.showPickerP = false;
		},
		onConfirmbuildingId(v) {
			this.formObj.buildingName = v.text;
			this.formObj.buildingId = v.value;
			this.buildingIdShow = false;
		},
		// 上传图片
		afterRead(file) {
			file.status = 'uploading';
			file.message = '上传中...';
			// 此时可以自行将文件上传至服务器
			// console.log(file.file);
			report({ file: file.file, action: 'repair_apply' }).then(res => {
				// console.log(res)
				const data = res.data;
				console.log(data.statusCode);
				if (data.statusCode == 200) {
					this.formObj.photoss.push({
						url: data.data.fileName
					});
					console.log('123');
					file.status = 'done';
				} else {
					file.status = 'failed';
					file.message = '上传失败';
				}
			});
		},
		// 删除照片
		beforeDelete(a, b, c) {
			console.log(a, b, c);
			if (a.status == 'done') {
				this.formObj.photoss.splice(b.index, 1);
				return true;
			} else {
				return true;
			}
		},
		// 表单提交
		onSubmit() {
			const ary = [];
			this.formObj.photoss.forEach(item => {
				ary.push(item.url);
			});
			this.formObj.photos = ary.join(',');
			this.isDisabled = true;
			commitRepairs(this.formObj)
				.then(res => {
					console.log(res);
					if (res.code == 200) {
						Toast.success('提交成功');
						setTimeout(() => {
							this.$router.replace({ path: '/serve/serve-list', query: { active: 1 } });
						}, 1000);
					} else {
						Toast.fail(res.message);
					}
				})
				.finally(() => {
					this.isDisabled = false;
				});
		},
		// 获取报修类型字典
		getType() {
			initDictOptions('zm_repairs_type').then(res => {
				//console.log('zj,zm_repairs_type',res)
				const ary = [];
				res.forEach(item => {
					ary.push({
						text: item.text,
						value: item.value
					});
				});
				this.columns = ary;
			});
		},
		// 报修位置
		getLocation() {
			initDictOptions('zm_repair_location').then(res => {
				// console.log(res)
				const ary = [];
				res.forEach(item => {
					ary.push({
						text: item.text,
						value: item.value
					});
				});
				this.repairPartAry = ary;
			});
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/ .van-field__label {
	color: #333333 !important;
	font-size: 16px;
}
.page_main {
	background-color: #f7f8fa;
	height: 100%;
	min-height: 100vh;
}
.title_area {
	height: 34px;
	line-height: 34px;
	background-color: #f7f8fa;
	color: #999999;
	padding: 0 14px;
}
</style>
